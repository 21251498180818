export default {
  "404": {
    "back-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page demandée n'a pas été trouvée !"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page introuvable"])}
  },
  "account": {
    "last-logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières connexions"])},
    "last-logins-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "last-logins-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "links": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens"])},
      "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le lien"])},
      "unlink-confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce lien ?"])},
      "unlink-confirm-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le lien"])}
    },
    "my-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes statistiques"])},
    "player-verify": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien maintenant"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "no-player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore lié votre compte avec votre compte Minecraft"])},
      "player-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du joueur"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans Minecraft, entrez la commande <code class='color-primary'>/account-verify</code> pour générer un code de vérification. Saisissez ensuite votre nom Minecraft et le code généré ici."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier un compte Minecraft"])}
    },
    "rank-expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang valable jusqu'au"])},
    "register-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
    "state": {
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de la connexion"])},
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])}
  },
  "acp": {
    "licenses": {
      "AIR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flightlicense"])},
      "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivinglicense"])},
      "WEAPON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weaponlicense"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add License"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])}
    },
    "players": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joueurs"])}
    }
  },
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "admin": {
    "chats": {
      "closedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "closedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "selectChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "download": {
      "delete-download-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce téléchargement ?"])},
      "delete-download-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le téléchargement"])},
      "delete-section-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cette section de téléchargement ? Cela supprimera également tous les autres téléchargements !"])},
      "delete-section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de la section du téléchargement"])}
    },
    "permissions": {
      "addPermSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission has been added successfully"])},
      "groups": {
        "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau groupe"])},
        "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le groupe?"])},
        "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
        "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])}
      },
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Autorisation"])},
      "ranks": {
        "edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le grades"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
        "mcPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft - Prefix"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grades"])},
        "titlesingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations"])}
    },
    "player": {
      "char-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possibilité de caractère"])},
      "characters": {
        "headers": {
          "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
          "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
          "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
          "id-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
          "last-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernièrement joué"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "player-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player ID"])},
          "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working?"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character"])}
      },
      "mod-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première utilisation"])},
      "mod-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ModID"])},
      "mod-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière utilisation"])},
      "mod-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du Mod"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
      "warnings": {
        "headers": {
          "proof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuve"])},
          "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])},
          "warned-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Averti à"])},
          "warner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warner"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissements"])}
      }
    },
    "players": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du joueur"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joueur"])},
      "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID"])}
    },
    "pre-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration - "])}
  },
  "api-errors": {
    "AIRPORT_BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been banned at the Airport, please contact our Support on Teamspeak"])},
    "CHAT_ALREADY_OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an open Chat of this type!"])},
    "CHAT_TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently banned from opening this type of chat!"])},
    "CONFIRMATION_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce code a déjà expiré. Vous recevrez un nouveau code."])},
    "CONFIRMATION_TOO_MUCH_FAILURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop de tentatives ratées. Vous recevrez un nouveau code."])},
    "CONFIRMATION_WRONG_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code non valide"])},
    "EMAIL_ALREADY_REGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet e-mail est déjà utilisée"])},
    "EMAIL_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail non valide"])},
    "INVALID_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code non valide"])},
    "INVALID_LICENSE_FOR_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid License for Licensetype"])},
    "INVALID_LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse électronique ou le mot de passe ne correspondent pas"])},
    "MISSING_FIELD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all requierd fields"])},
    "NO_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune autorisation"])},
    "PLAYER_HAS_ALREADY_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le joueur dispose déjà de cette autorisation (éventuellement par le biais d'un groupe ou d'un rang)"])},
    "QUIZ_BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have failed the quiz, you do not need to apply to be unbanned for this"])}
  },
  "auth": {
    "accept-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les <a class='color-primary' target='_blank' href='/terms'>GTCs</a>"])},
    "already-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte ?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription complète"])},
    "confirm-already-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse électronique n'a pas encore été vérifiée. Entrez le code pour compléter le processus d'enregistrement"])},
    "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devriez recevoir un courriel contenant un code de confirmation dans les plus brefs délais. Saisissez ce code pour terminer l'inscription. (N'oubliez pas de vérifier vos spams)."])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invité :"])},
    "labels": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe"])},
      "password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de compte ?"])},
    "resend-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau un e-mail"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
    "reset-password-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu devrais recevoir un e-mail avec un code dans les plus brefs délais. Saisis le code pour définir un nouveau mot de passe. (N'oublie pas de vérifier vos spams)"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre"])}
  },
  "chat": {
    "APPLY_BUILDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "APPLY_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "APPLY_SUPPORTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "CHAR_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "SECCHAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "UNBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open new Chat"])},
    "newOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new Chat has been opened! 🎉"])},
    "newOpenInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further information await you after opening the chat"])},
    "noChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any Chats!"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "premessage": {
      "autoparameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic parameter"])},
      "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check message"])},
      "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messageparameter"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Member"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagetext"])}
    },
    "premessages": {
      "SECCHAR_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secchar accept"])},
      "SECCHAR_DENY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secchar deny"])},
      "SECCHAR_RECIEVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secchar recieved"])},
      "UNBAN_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept unban"])},
      "UNBAN_DENY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny unban"])},
      "UNBAN_RECIEVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unban recieved"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
    },
    "reasons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add custom reason"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "secchar": {
        "notEnoughEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient reason for entry"])},
        "recentBan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent ban from our server"])},
        "storyUndetailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History too undetailed"])},
        "storyfoggy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconsistency within the story"])},
        "toLessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too little information about your character"])}
      },
      "unban": {
        "notEnough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient application for removal"])},
        "notRPfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We see no reason how you could promote our RP"])},
        "toLessTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too little time between ban and application"])}
      }
    },
    "side": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "precreated": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Finished messages"]), _normalize(["Finished message"])])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])}
    },
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Demande"]), _normalize(["Demandes"]), _normalize(["Ton demandes"])])},
    "welcome": {
      "APPLY_BUILDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your name and age</li> <li>Why StateMC</li> <li>Your experience as a builder</li> <li>What are you good at building</li> <li>Your experience with modblocks</li> <li>Your experience with RP and StateMC</li> </ul>"])},
      "APPLY_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your name and age</li> <li>Why StateMC</li> <li>How you want to help us</li> <li>Your knowledge</li> <li>As well as your skills in dealing with Git and GitLab, for example</li> </ul>"])},
      "APPLY_SUPPORTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your name and age</li> <li>Why StateMC</li> <li>Your experience as a supporter</li> <li>Your experience with RP and StateMC</li> </ul>"])},
      "CHAR_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>The reason for the character deletion</li> <li>>our plan for your future on StateMC</li> </ul>"])},
      "SECCHAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your playing time</li> <li>Your character's reason for entry</li> <li>Your character's history</li> <li>Your character's attributes</li> </ul>"])},
      "UNBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong> Please give us the following information: </strong> <ul class=\"ml-7\"> <li>Your reason for getting banned</li> <li>Your Ban duration</li> <li>The reason why we should unban you</li> </ul>"])}
    }
  },
  "cookies": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons des cookies et des technologies similaires sur notre site web et traitons des données personnelles vous concernant, telles que votre adresse IP. Nous partageons également ces données avec des tiers. Le traitement des données peut être effectué avec votre consentement ou sur la base d'un intérêt légitime."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce site web utilise des cookies"])}
  },
  "dialog": {
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarde"])}
  },
  "downloads": {
    "download-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "open-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargements"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
  "games": {
    "freeroam": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans Freeroam, chaque joueur a la liberté de choisir ses propres activités et objectifs. Vous pouvez affronter d'autres joueurs dans des courses de voitures, tirer à tout va dans la région, cultiver ou participer aux défis réguliers et passionnants. Il n'y a pas de règles ou de contraintes fixes et les possibilités sont infinies."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])}
    },
    "gungame": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame consiste à s'améliorer dans une compétition avec d'autres joueurs en tuant des ennemis. Chaque fois que vous réussissez à tuer un ennemi, vous obtenez une nouvelle arme, ce qui vous conduit à des défis de plus en plus grands. Le but du jeu est d'atteindre le niveau le plus élevé en réussissant ces défis et en tuant le dernier ennemi avec une batte de baseball."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])}
    },
    "roleplay": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeu de rôle consiste à se mettre dans la peau d'un personnage fictif et de simuler sa vie. Il peut s'agir de différents types de personnages tels qu'un gangster, un policier ou un homme d'affaires. L'objectif est de créer des situations divertissantes et passionnantes entre les joueurs."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])}
    },
    "ttt": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans TTT, les joueurs sont divisés en trois groupes différents : Traîtres, Détectives et Innocents. La tâche des Innocents est de traquer et d'éliminer les Traîtres, tandis que les Détectives les aident grâce à leurs capacités spéciales. De leur côté, les traîtres ont pour objectif de tuer tous les innocents et les détectives le plus discrètement possible avant que leur véritable identité ne soit révélée."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])}
    }
  },
  "home": {
    "card1": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateMC se différencie des serveurs vanilla en utilisant des mods innovants pour améliorer l'expérience de jeu et offrir des expériences uniques."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de StateMC"])}
    },
    "card2": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre plugin vocal spécialement développé pour Teamspeak permet une excellente qualité audio dans l'expérience de jeu et est utilisé dans Roleplay, Freeroam et TTT pour créer une expérience unique sur Minecraft."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre VoicePlugin"])}
    },
    "card3": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous optimisons l'expérience de jeu en utilisant des mods spéciaux comme DynamX avec une physique et des véhicules réalistes, ainsi que notre propre mod qui intègre des overlays unique et un téléphone dans le jeu."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos mods"])}
    },
    "cards-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos de nous"])},
    "game-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de"])},
    "games": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roleplay"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeu de rôle consiste à incarner des personnages fictifs et de vivre des situations divertissantes et passionnantes en intéragissant avec les autres joueurs sur le serveur."])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gungame"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans GunGame, l'objectif est d'augmenter votre niveau en tuant des ennemis et en vous battant avec de nouvelles armes jusqu'à ce que vous réussissiez le meurtre final avec une batte de baseball."])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
        "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ttt"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le TTT, les Innocents doivent trouver et vaincre les Traîtres, tandis que ces derniers essaient de ne pas être détectés et de tuer tous les autres."])}
      }
    ],
    "games-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos modes de jeu"])},
    "social-media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sociaux"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre équipe"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])}
  },
  "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
  "imprint": {
    "address": {
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["592 31 Křídla"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchéquie"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miloš Řezníček"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Křídla 111"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "tmg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations selon le §5 TMG"])}
    },
    "contact": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt", "@", "statemc.de"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impression"])}
  },
  "join": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous rejoindre"])},
    "install-modpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer Modpack"])},
    "install-modpack-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Installez Java 8 (64 bits) si vous ne l'avez pas <br> 2. Installez Technic Launcher si vous ne l'avez pas <br> 3. Recherchez et téléchargez StateMC dans Technic Launcher <br> 4. Dans Technic Launcher, attribuez 3 à 6 Go de Ram au Modpack (options du lanceur - > paramètres Java) <br> 5. Maintenant, appuyez sur Jouer"])},
    "install-ts-plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer le plugin Teamspeak"])},
    "install-ts-plugin-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Installez Teamspeak 3 (64 bits) si vous ne l'avez pas <br> 2. Installez L'Addon TS3 <a href='/downloads 'class=' color-primary'> Downloads</a > <br> 3. Lancez Teamspeak et cliquez sur ts.statemc.de puis rejoindre <br> 4. Maintenant, vous devez rejoindre le serveur RP dans le hall. Dans le Chat (in-game), vous recevrez un Code <br> 5. Envoyez le Code reçu dans le Chat \"StateMC\" sur Teamspeak <br> 6. Rejoignez la chaîne \"Ingame-Warteraum\" et reconnectez-vous au serveur RP"])},
    "man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour jouer sur StateMC, vous avez besoin de notre Modpack. Pour les modes de jeu basés sur la voix comme Roleplay ou TTT, vous avez également besoin de notre Plugin Teamspeak. L'Installation est rapide et vous offre une expérience de jeu unique."])},
    "possible_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreurs possibles"])},
    "possible_errors-man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Je ne peux allouer que 1 Go dans le TechnicLauncher</b> <br> Vous avez besoin de la version Java 64 bits. Pour les téléchargements: <a href='/downloads' class='color-primary'>Downloads </a><br><br> <b>Je ne peux pas installer le Plugin TS3.</b> <br>Vous avez besoin de la Version 64 bits de Teamspeak. Pour les téléchargements: <a href='/downloads' class='color-primary'>Downloads </a><br><br><b>Je dis que je suis déjà vérifié</b> <br> Si votre compte Minecraft est déjà lié à une autre identité Teamspeak, vous pouvez utiliser la commande '/unlink' dans le Lobby pour les supprimer. Après cela, vous pouvez vérifier à nouveau comme d'habitude"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment se rendre sur StateMC"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller Au Tutoriel Vidéo:"])},
    "video-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers le tutoriel vidéo"])}
  },
  "mojangwarn": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce serveur n'est pas lié à Mojang AB ou à Microsoft."])}
  },
  "multiplayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multijoueur"])},
  "pay-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poursuivre vers le paiement"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
  "playercount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joueurs actifs"])},
  "policy": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisation de notre site web est généralement possible sans fournir de données personnelles. Dans la mesure où sur nos côtés des données personnelles (telles que le nom, l'adresse ou les adresses e-mail) sont collectées, cela se fait dans la mesure du possible sur une base volontaire. Ces données ne seront pas divulguées à des tiers sans votre consentement exprès.<br /><br />Nous attirons l'attention sur le fait que la transmission de données sur Internet (par exemple la communication par e-mail) présente des lacunes en matière de sécurité. Une protection complète des données contre l'accès par des tiers n'est pas possible.<br /><br />L'utilisation des données de contact publiées dans le cadre de l'obligation d'impressum par des tiers pour envoyer des publicités et du matériel d'information non sollicités est expressément exclue par la présente. Les exploitants des pages se réservent expressément le droit d'engager des poursuites judiciaires en cas d'envoi d'informations promotionnelles non sollicitées, telles que des spams."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])}
  },
  "provide-minecraft-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir votre nom Minecraft:"])},
  "quickjoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre"])},
  "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "searchTitles": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "playerID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "playerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
  "shop": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boutique"])}
  },
  "shopping-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AJOUTER AU PANIER"])},
  "singleplayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joueur unique"])},
  "stats": {
    "community": {
      "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La récréation sur StateMC"])},
      "playTimePerPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de jeu par joueur"])},
      "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joueurs enregistrés"])},
      "projectStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis le début du projet"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes enregistrés"])}
    },
    "events": {
      "ATM_BLOWN_UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM explosé"])},
      "DEATH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mort"])},
      "DRY_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séchage de la cocaïne"])},
      "DRY_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séchage de la marijuana"])},
      "FARM_APPLES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pommes cueillies"])},
      "FARM_COCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coca cueilli"])},
      "FARM_FISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poisson capturé"])},
      "FARM_IRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fer dégradé"])},
      "FARM_LETTUCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salade cueillie"])},
      "FARM_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marijuana récoltée"])},
      "FARM_MUSHROOMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champignons cueillis"])},
      "FARM_STRAWBERRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraises cueillies"])},
      "FARM_TREES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbres tombés"])},
      "KARMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma"])},
      "KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
      "LOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdre"])},
      "MONEY_EARNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argent gagné"])},
      "MONEY_SPENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenser de l'argent"])},
      "PACK_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachet cocaïne"])},
      "PACK_MARIHUANA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachet de Marijuana"])},
      "PROCESS_CARBON_TO_PROPANE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le charbon transformé en propane"])},
      "PROCESS_COCAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processus cocaïne"])},
      "PROCESS_IRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fer à repasser"])},
      "PROCESS_WATER_TO_H2O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation de l'eau en H2O"])},
      "PROCESS_WOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bois transformé"])},
      "PROCESS_WOOD_TO_CARBON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bois transformé en charbon"])},
      "TRASH_SEARCHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poubelle fouillée"])},
      "WIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Victoire"])},
      "WIN_CHALLENGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défi 1. Lieu"])},
      "WIN_CHALLENGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge 2ème place"])},
      "WIN_CHALLENGE_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défi 3. Lieu"])},
      "WRONG_KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faux meurtre"])}
    },
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "modes": {
      "freeroam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
      "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
      "gungame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gungame"])},
      "ttt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])}
    },
    "per-side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par camp"])},
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joueurs"])},
    "playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de jeu"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "times": {
      "ALL_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les temps"])},
      "DAILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journalier"])},
      "MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
      "WEEKLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
    "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 5 aujourd'hui"])}
  },
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "terms": {
    "mc": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em class='color-secondary'>Dernière modification : 04.06.2021</em><br /><br />Afin de vous fournir les fonctionnalités de notre réseau et de mettre en œuvre des mesures de sécurité contre les pirates et les violations des règles, nous avons besoin de votre consentement pour collecter et traiter les données suivantes concernant votre profil Minecraft (UUID) :<br /><br />- Adresses IP, logins, journaux d'accès<br />- Statistiques dans les jeux (kills / morts etc), temps de jeu<br />- Comportement dans le jeu et le chat<br />- Paramètres / Bans / Mutes<br />- Lien vers Teamspeak<br /><br />Nous sécurisons vos données selon l'état de l'art et ne les vendons pas à des tiers. Les statistiques sont consultables publiquement pour chaque compte dans le but de fournir des classements.<br /><br />En cliquant sur <strong class='color-primary'>Accept</strong>, vous acceptez le stockage et le traitement des données sur la base de cette déclaration.<br /><br />En outre, vous acceptez de reconnaître et de respecter les <a href='/rules'>règles du serveur</a>."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur Minecraft"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])},
    "ts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em class='color-secondary'>Dernière modification : 04.06.2021</em><br /><br />Pour vous fournir les fonctionnalités de notre serveur Teamspeak et mettre en œuvre des mesures de sécurité contre les pirates et les violations des règles, nous avons besoin de votre consentement pour collecter et traiter les données suivantes concernant votre identité TS :<br /><br />- Adresses IP, logins, journaux d'accès, plateforme/système d'exploitation utilisé<br />- Pseudo / groupes de serveurs et de canaux<br />- Banns / Kicks<br />- Voix<br /><br />Pour le fonctionnement de notre serveur Teamspeak, nous utilisons le logiciel officiel <a href='https://www.teamspeak.com/fr/downloads/#server' target='_blank'>logiciel de serveur Teamspeak</a>. Celui-ci traite les contenus vocaux et les messages de chat envoyés dans le but d'utiliser Teamspeak. Vous trouverez de plus amples informations ici.<br />Nous sécurisons vos données selon l'état de la technique et ne les vendons pas à des tiers.<br /><br />Votre voix peut et peut être enregistrée et publiée dans le canal marqué par '\" Ingame \".<br /><br />En utilisant le StateMC.de Teamspeak, vous acceptez le traitement des données sur la base de la déclaration ci-dessus.<br /><br />En outre, vous acceptez de prendre connaissance et de respecter les <a href='/rules'>règles de Teamspeak</a>."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur Teamspeak"])}
    }
  },
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "units": {
    "DAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
    "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures"])},
    "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procès-verbal"])}
  },
  "validation": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doit être accepté"])},
    "code-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composé de 5 caractères"])},
    "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique invalide"])},
    "invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doit contenir un nombre ou un caractère spécial"])},
    "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe est invalide"])},
    "password-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 8 caractères"])},
    "password-upper-and-lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une majuscule et une minuscule"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validation.uuid"])}
  },
  "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéos"])}
}