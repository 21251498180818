export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles"])},
  "list": [
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne soyez pas un idiot"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouez un jeu de rôle et répondez au jeu de rôle des autres joueurs, restez toujours dans votre personnage et ne faites jamais d'OOC (Out of Character), mais restez toujours IC (In Character) une fois que vous êtes sur le serveur."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre vie est le bien le plus important"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre vie sur le serveur RolePlay est la chose la plus précieuse que vous puissiez posséder sur l'ensemble du serveur, ne l'abandonnez pas et soyez précieux avec elle.Vous devez par conséquent jouer RP en faisant des choix qui vous gardent en vie."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'entrée"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dès que vous entrerez sur le serveur, vous devrez créer votre personnage, en pensant à un prénom, un nom et une date de naissance. N'utilisez pas un prénom et un nom qui existent déjà dans le monde, mais soyez créatif et pensez à votre propre nom. Veillez à ce que la date de naissance soit réaliste et corresponde à votre jeu de rôle, tout comme le nom (personne majeure)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microphone / Casque"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez disposer d'un microphone/écouteur en état de marche pour pouvoir jouer raisonnablement sur le serveur. Dans le cas contraire, vous ne pouvez jouer sur le serveur."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez l'obligation d'être dans le canal 'en jeu', (sur TeamSpeak) dès que vous entrez sur le serveur RolePlay. Vous n'êtes pas autorisé à communiquer avec des personnes en jeu RP sur d'autres plateformes de communication comme Teamspeak ou Discord."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plate-forme de communication"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de créer et de rejoindre des plates-formes de communication qui offrent la possibilité de faire du méta-game. Cela inclut, par exemple, les discord de gang/faction ou les docs Google.Si une plateforme de communication indépendante est nécessaire en dehors du RP, elle doit être approuvée par un modérateur ou un administrateur du support. Les exceptions sont les factions d'État (Police etc) qui sont fermement ancrées dans le jeu, ainsi que certaines zones sur le serveur Discord officiel de StateMC."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troll"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit d'importuner constamment les autres joueurs sur le serveur. Exemple : Conduire une voiture en permanence devant les mêmes joueurs et jouer de la musique."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soundboard dans le RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est autorisé d'utiliser un soundboard dans le RolePlay comme support pour son RolePlay. La musique et les sons protégés par des droits d'auteur ne peuvent pas être joués dans le jeu de rôle.Exemple : en tant que bruit de fond pendant une activité, en tant que musique de fond dans une discothèque."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masques"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de reconnaître une personne à sa voix si cette personne porte un masque complet (cagoule). (Les foulards buccaux ne sont PAS considérés comme des masques complets)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieux amis"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur ce serveur, la règle des 'vieux amis', s'applique, ce qui signifie que vous êtes autorisé à entrer dans cet état avec une personne déclarée comme 'vieux ami'."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-perso"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous êtes en mesure de créer un autre personnage, vous devez adhérer aux choses suivantes : <br>➥ Le personnage ne peut pas utiliser en jeu des informations qu'un autre personnage que vous avez créé a reçues.<br>➥ Le personnage ne peut pas avoir la même voix que vos autres personnages.."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce des tirs"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si un échange de tirs ou de coups avec une arme (ou un objet utilisé abusivement comme arme) est imminent, une annonce doit être faite avant cette action. L'adversaire doit disposer d'un temps de réaction d'au moins 5 secondes avant qu'une attaque avec l'arme ait lieu."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDM interdit"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est l'abréviation de Random Deathmatch, ce qui signifie que vous ne pouvez pas simplement tirer ou tuer des joueurs comme des animaux sans un contexte de jeu de rôle."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDM interdit"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Vehicle Deathmatch (VDM) consiste à écraser intentionnellement d'autres joueurs et est strictement interdit."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta Gaming"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous utilisez des informations obtenues en dehors du jeu dans un jeu de rôle, cela s'appelle du métagaming et est strictement interdit."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans un jeu de rôle, vous devez laisser à chacun le temps d'agir. Si vous ne laissez pas à votre adversaire le temps de réagir, cela s'appelle du 'Power RP' et est interdit sur le serveur. Exemple : Vous menacez quelqu'un avec une arme, il ne lève pas immédiatement les bras et vous lui tirez dessus parce qu'il ne réagit pas immédiatement."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le RP d'échec fait référence à des actions qu'il serait impossible d'accomplir dans la vie réelle ou qui sont tout simplement irréalistes. Exemple : Se suicider pour réapparaitre avec toute sa vie."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex-RP interdit"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reconstitution d'un viol en jeu RP est interdite. De même, la mise en scène de tout 'sexe-RP' est interdite."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RP Escape"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de fuir activement les situations de jeu de rôle, ce qui signifie que vous ne devez pas vous déconnecter des situations de jeu de rôle actives ou lorsque vous êtes inconscient."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RP-Tod"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous seul décidez quand laisser mourir votre personnage et quand ne pas le faire, personne d'autre ne décide de la mort de votre personnage. Une mort RP doit être annoncée dans le support et approuvée par un modérateur avant de se produire. Exemple : Vous êtes dans un gang de rue, recevez un 'Blood out' par un tir dans la tête et devenez inconscient, décidez si vous voulez que votre personnage meurt.(Rappelez-vous la première règle 'Jouer RP')."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raids"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vol d'autres joueurs n'est autorisé que si 2 étoiles de la liste d'onglets sont colorées en jaune, sinon il y aura une pénalité. Veuillez noter que les vols ne sont autorisés qu'en dehors des 'zones de sécurité' définies."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépositaire"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si des voitures sont extorquées du dépôt à l'aide de vols (y compris la prise d'otages) contre la police, cela n'est également autorisé que si 2 étoiles dans la liste des onglets sont colorées en jaune."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise d'otages"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prise d'otages ne doit se faire que lorsqu'au moins 3 étoiles de la liste des onglets sont colorées en jaune, sinon vous serez sanctionné.N'oubliez pas de ne prendre des otages qu'en dehors des 'zones de sécurité' définies."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motifs de regroupement"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voulez créer un gang, vous pouvez le faire dans RolePlay et vous n'avez pas besoin d'enregistrer quoi que ce soit au préalable, car selon nous, les gangs doivent être créés dans RolePlay et non par une application écrite en dehors de RolePlay."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regroupement (prise d'otages + braquages)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous devez agir avec un groupe de civils (par exemple un gang, une mafia, etc.) contre la police, en termes de prise d'otages ou de braquage, un maximum de 10 participants peuvent être activement impliqués dans cette scène. Actif signifie qu'au début, un maximum de 10 personnes peuvent être impliquées dans la scène. Pendant la scène, aucune autre personne n'est autorisée à participer, que le nombre maximum de 10 participants soit atteint ou non."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquage de regroupement"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un gang doit être uniformément reconnaissable, soit avec la même couleur ou le même vêtement. Cependant, cela ne doit être affiché de manière évidente que pendant les guerres de gangs et non lorsque vous êtes simplement en train de sortir ou de lancer des actions."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisme des véhicules"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comme nous sommes toujours dans Minecraft et que les voitures sont bonnes mais pas parfaites, vous devez vous assurer de conduire avec les voitures de manière aussi réaliste que possible."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramédic d'urgence"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous n'êtes pas réanimé alors que vous êtes inconscient, vous serez réanimé dans le comas par un ambulancier (respawn) et vous vous réveillerez à l'hôpital. Une fois que vous vous réveillez à l'hôpital, vous ne pouvez plus vous souvenir des 30 dernières minutes et laissez vos blessures être examinées et traitées  par un vrai médecin (joueur)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer l'inconscience"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous vous évanouissez et vous réveillez à l'hôpital, vous avez le devoir de continuer votre RolePlay, même si vous êtes mort à cause d'un bug. Il vous suffit de contacter le staff après avoir terminé votre jeu de rôle, afin que le problème puisse être clarifié."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconscience (réanimation)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous perdez connaissance, vous ne pouvez vous réanimer que si aucun joueur ne se trouve à proximité. Cette règle ne s'applique pas si les autres joueurs indiquent clairement qu'aucun médecin n'apparaîtra (par exemple, aucun médecin en ligne) ou si les joueurs environnants vous ignorent.Exemple : La fusillade est toujours active et personne ne se soucie de vous ou ne fait de signes pour contacter un médecin."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zones de sécurité"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous trouverez ci-dessous une liste des zones de sécurité où les gens ne peuvent pas être volés ou pris en otage.Zones de sécurité:➥ ATM➥ Banque➥ Aéroport."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'échapper vers les zones de sécurité"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuir une scène RP dans une zone de sécurité est strictement interdit et entraîne la suspension temporaire de la règle 'Zones' pour cette situation. Exemple: Vous fuyez des poursuivants dans une zone de sécurité qui veulent vous voler."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'envoi de photos de la vie réelle par SMS est interdit. Seules les captures d'écran de Minecraft qui ne contiennent pas de parties de l'Hud ou du GUIS peuvent être envoyées, ainsi que les captures d'écran de documents texte. Spammer les gens dans le roleplay est strictement interdit!"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situations extrêmes"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute forme de racisme, de viol ou de torture excessive dans le cadre du jeu de rôle est interdite. Cela inclut le fait de jouer des situations extrêmes, telles que les 'crimes de haine raciale', le 'viol', le 'terrorisme' ou le 'démembrement' ou la découpe de parties du corps."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge minimum"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous attendons de chaque joueur de jeu de rôle qu'il ait au moins 14 ans et qu'il se comporte de manière appropriée."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covid-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer une personne atteinte du Coronavirus n'est pas souhaité sur notre projet."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vol à main armée"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de braquer quelqu’un sans raison valable.Tout braquage abusif sera sanctionné."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règle des armes"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisation d'une arme comme moyen de pression ou de pouvoir est légitime, mais elle ne doit être effectuée qu'après des contextes RP antérieurs et ne doit pas être considérée comme le premier acte. Les contextes antérieurs incluent la tentative de clarifier la situation avec des mots. Ceci s'applique à tous les joueurs, quelle que soit leur faction."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFK-Farming"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit d'être AFK sur le serveur avec des intentions de profit. Par exemple, en attendant la paie ou en traitant des matières premières. Vous devez toujours être disponible pendant ces activités."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOC-Talk"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute discussion OOC est strictement interdite. Cela inclut le simple remplacement de termes OOC, tels que \"bouton\" par \"muscle\". Des paraphrases créatives doivent être utilisées à la place. Les phrases telles que \"Je suis à court de tête/de pensées\" ne sont pas non plus souhaitables. Il est préférable d'utiliser des phrases alternatives telles que \"Je regarde rapidement mon téléphone pour vérifier mes messages\"."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspeak et Discord"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injures"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout propos offensant, raciste, sexiste et extrémiste (noms, noms de canaux, avatars, messages, ...) est interdit."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respect"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insulter d'autres personnes est interdit. Il est interdit de menacer d'autres personnes, quelle que soit l'activité. Il faut veiller à ne pas crier dans tout espace de conversation librement accessible. Il ne faut pas parler de manière irrespectueuse à une autre personne en raison de son expression."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruit"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de déformer sa propre voix. Faire ou imiter des sons et des chants inappropriés est interdit. Il est interdit d'émettre des sons ou de la musique. Il faut veiller à ne pas faire de bruit trop fort ou trop bruyant."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement et diffusion des appels"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enregistrement des conversations ainsi que le streaming sans la permission de toutes les personnes présentes sont interdits. (Exception : le canal 'Ingame' sur Teamspeak)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vol d'identité"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de falsifier les autres de quelque manière que ce soit."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation du support"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de profiter de notre assistance (par exemple, en l'utilisant sans en avoir besoin ou en posant des questions inutiles)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scripts"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisation de scripts pour créer des canaux, des bots et autres est interdite."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug using"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'exploitation ou la diffusion d'un bug n'est pas autorisée."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie privée"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est strictement interdit de publier ou de partager les informations personnelles d'autres personnes sans leur permission. Des exceptions sont faites pour les affaires de police, mais elles doivent être demandées aux propriétaires de ce serveur TeamSpeak."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploit rank"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si une personne possède un groupe de serveurs supérieur au groupe de serveurs par défaut, il lui est strictement interdit d'utiliser ses droits à des fins non spécifiées (par exemple, donner des coups de pied ou bannir d'autres personnes sans raison)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attaques"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute attaque contre d'autres personnes et nos serveurs (sous forme de DDOS, PokeBot, piratage, ...) est interdite."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contournement de l'interdiction"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de contourner une interdiction à partir de nos serveurs en utilisant un VPN, par exemple."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TS³ Nom"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de choisir un nom TS³ qui contient un mélange OOC/IC (par exemple [Greens OG], [Police Chief], Max Mustermann, etc.) Sont également interdits les noms peu clairs comme ? ??? et XXXX."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client modifications (mods)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute modification du client, qui donne au joueur un avantage sur les autres joueurs, est généralement interdite. Les modifications non autorisées sont par exemple :<br/>➥ Xray<br/>➥ KillAura<br/>➥ Minimap."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugs"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute forme de bugues et leur exploitation est strictement interdite. Si vous découvrez un bug, vous devez le signaler à l'équipe."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évasion des sanctions"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit d'éviter les pénalités en ayant un deuxième compte, par exemple."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabilité du compte"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chacun est responsable de son propre compte. Par conséquent, une pénalité sera imposée même si une deuxième personne autre que le propriétaire commet une violation des règles."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respect dans le chat"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un comportement respectueux doit être observé dans le chat. Le spam de messages est interdit sous peine de santions."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDM"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit de tuer d'autres personnes sans être sûr qu'il s'agit d'un joueur de l'équipe adverse."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghosting"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune information sur le jeu en cours ne peut être partagée par des moyens de communication extérieurs."])}
        }
      ]
    }
  ]
}