export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regeln"])},
  "list": [
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei kein Idiot"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele RolePlay und gehe auf das RolePlay von deinen Mitspielern ein, bleibe stets in deiner Rolle und gehe nie OOC (Out of Character), sondern bleibe immer IC (In Character), sobald du auf dem Server bist."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Leben ist das wichtigste Gut"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Leben auf dem RolePlay Server ist, das wertvollste was du auf dem ganzen Server besitzen kannst, gib es nicht einfach auf und geh wertvoll damit um."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreisebestimmungen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald du den Server betrittst, musst du deinen Charakter erstellen, und dir dabei ein Vor und Nachnamen sowie ein Geburtsdatum überlegen. Bitte nehme hierbei keinen Vor- und Nachnamen, der in der Welt bereits existiert, sondern sei kreativ und denke dir einen eigenen aus. Hierbei sind allerdings doppeldeutige oder beleidigende Namen wie zum Beispiel: Fixi Hartmann oder Rainer Zufall verboten. Achte bei dem Geburtsdatum genau wie bei dem Namen darauf, dass dieser realistisch ist und zu deinen RolePlay passt."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikrofon / Headset"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du solltest ein funktionierendes Mikrofon/Headset besitzen, um auf den Server vernünftig spielen zu können."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikation"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast die Pflicht, sobald du den RolePlay Server betrittst im dafür vorgesehenen 'Ingame' Channel zu sein. Dabei darfst du nicht nebenbei auf anderen Kommunikationsplattformen wie z.B. Teamspeak oder Discord mit Leuten im RolePlay kommunizieren."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikationsplattformen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Erstellen und beitreten von Kommunikationsplattformen, welche die Möglichkeit bieten Meta-Gaming zu betreiben, sind verboten. Darunter zählen beispielsweise sogenannte Fraktions-Discords oder Google Tabellen. Sollte eine unabhängige Kommunikationsplattform außerhalb vom RP benötigt werden, muss diese im Support von einem Moderator oder Administrator genehmigt werden. Ausnahme sind hierbei staatliche Fraktionen welche fest im Spiel verankert sind, sowie bestimmte Bereiche auf den offizielen StateMC Discord-Server."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trolling"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ständige Nerven von anderen Spielern auf den Server ist verboten. Beispiel: Mit einem Auto dauerhaft an die gleichen Spieler vorbeifahren und Musik abspielen."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soundboard im RolePlay"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist erlaubt als Unterstützung für sein RolePlay ein Soundboard im RolePlay zu verwenden. Copyright geschützte Musik und Sounds dürfen im Roleplay nicht abgespielt werden. Beispiel: als Geräusch Untermalung bei Ausübung einer Tätigkeit, als Musik-Bot in einer Disco"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masken"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist verboten eine Person anhand seiner Stimme zu erkennen, wenn diese Person eine Vollmaskierung (Sturmhaube) trägt. (Mundtücher zählen NICHT als Vollmaskierung)"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte Freunde"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf diesen Server herrscht die 'alte Freunde' Regel, was bedeutet das du mit einen als 'alter Freund' deklarierte Person in diesen Staat einreisen darfst."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MultiChar"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solltest du einen weiteren Charakter erstellen können musst du dich an folgende Dinge halten: <br/>➥ Der Charakter darf keine Informationen Ingame benutzen, die ein anderer von dir erstellter Charakter bekommen hat.<br/>➥ Der Charakter darf nicht die selbe Stimme haben, wie deine anderen Charaktere.</li></ul>"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schussankündigung"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte es so weit kommen, dass ein Schusswechsel oder ein Schlagabtausch mit einer Waffe (oder einem Gegenstand, der als Waffe zweckentfremdet wird) bevorsteht, dann muss eine Ankündigung vor dieser Handlung erfolgen. Dem gegenüberstehenden muss eine Reaktionszeit von mindestens 5 Sekunden gegeben werden, bevor ein Angriff mit der Waffe erfolgt."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDM"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steht für Random Deathmatch und bedeutet, dass du nicht einfach ohne einen angebrachten RolePlay Hintergrund auf Spieler so wie Tiere schießen bzw. diese töten darfst."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDM"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Deathmatch (VDM) ist das absichtliche Überfahren von Mitspielern und ist strengstens verboten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta Gaming"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solltest du Informationen, die du außerhalb vom Spiel bekommst im RolePlay verwenden, so spricht man von Metagaming und ist strengstens verboten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im RolePlay solltest du jeden genug Zeit zum Handeln lassen, solltest du dein Gegenüber nicht genug Zeit zum Reagieren lassen nennt man das 'Power RP' und ist auf den Server untersagt. Beispiel: Du bedrohst jemanden mit einer Waffe, er macht nicht die Arme sofort hoch und du erschießt ihn einfach, weil er nicht sofort reagiert."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Fail-RP werden Aktionen bezeichnet, die im realen Leben unmöglich zu bewältigen wären oder einfach nur unrealistisch sind. Beispiel: Wenn man sich extra tötet, um wieder volles Leben zu bekommen."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Nachspielen im RolePlay von Vergewaltigungen ist verboten. Genauso ist das Ausspielen von jeglichen 'Sex-RP' untersagt."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RP-Flucht"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist verboten aktiv vor RolePlay Situationen zu flüchten, was so viel bedeutet wie das man nicht ausloggen sollte bei aktiven RolePlay Situationen oder bei Bewusstlosigkeit."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RP-Tod"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur du allein entscheidest, wann du deinen Charakter sterben lässt und wann nicht, kein anderer entscheidet über den Tod deines Charakters. Ein RP-Tod muss vor dem Geschehen im Support angemeldet und von einem Moderator abgesegnet werden. Beispiel: Du bist in einer Straßengang, bekommst ein 'Blood out' durch ein Kopf Schuss und wirst bewusstlos, entscheide, ob du dein Charakter sterben lassen willst.<br/>(Denke an die erste Regel 'Sei kein Idiot')"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überfälle"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Spieler ausrauben also Überfälle zu tätigen ist ausschließlich erlaubt, wenn 2 Sterne in der Tab-Liste gelb gefärbt sind, andernfalls droht eine Strafe. Bitte beachte Überfälle einzig und allein außerhalb der definierten 'Sicherheitszonen' durchzuführen."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwahrstelle"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollten Autos aus der Verwahrstelle erpresst werden mithilfe von Überfällen (inklusive Geiselnahme) gegenüber der Polizei, so ist das auch nur erlaubt, wenn 2 Sterne in der Tab-Liste gelb gefärbt sind."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geiselnahme"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geiselnahmen sollten nur durchgeführt werden, wenn mindestens 3 Sterne in der Tab-Liste gelb gefärbt sind, andernfalls droht eine Strafe.<br/>Bedenke bitte ausschließlich Geiselnahmen außerhalb der definierten 'Sicherheitszonen' zu nehmen."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppierung Gründen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du eine Gang gründen, kannst du dies einfach im RolePlay machen und musst nichts vorher anmelden, denn Gangs sollten unserer Meinung nach aus dem RolePlay heraus entstehen und nicht irgendwie durch eine schriftliche Bewerbung außerhalb vom RolePlay."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppierung Mitglieder"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt zurzeit keine Mitglieder Begrenzung in Gruppierungen, achte darauf das du nicht jeden in deine Gruppierung aufnimmst, sondern wähle deine Mitglieder mit Bedacht."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppierung (Geiselnahme + Überfälle)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solltest du mit einer Gruppe von Zivilisten (z.B. Gang, Mafia, etc.) gegen die Polizei agieren, in Bezug auf Geiselnahme oder Überfälle, so dürfen maximal 10 Teilnehmer an dieser Situation aktiv beteiligt sein. Aktiv bedeutet, dass bei Beginn maximal 10 Leute an der Situation beteiligt sein dürfen. Während der Situation dürfen keine weiteren Personen sich mit an dieser Situation beteiligen, egal ob die maximale Anzahl von 10 Teilnehmern erreicht ist oder nicht."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppierung Kennzeichnung"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gang sollte einheitlich erkennbar sein, entweder mit der gleichen Farbe oder dem gleichen Kleidungsstück. Allerdings muss diese nur offensichtlich bei Gangkriegen zur Schau gestellt werden und nicht, wenn man einfach nur so unterwegs ist oder Aktionen startet."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge Realismus"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da wir immer noch in Minecraft sind und die Autos gut, aber nicht perfekt sind, sollte man darauf achten mit den Autos so realistisch wie möglich zu fahren."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notfallsanitäter"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solltest du während deiner Bewusstlosigkeit nicht reanimiert werden, dann wirst du von einem Notfallsanitäter (Respawn) notdürftig verarztet und wachst im Krankenhaus auf. Sobald du im Krankenhaus aufgewacht bist, kannst du dich an die letzten 30 Minuten nicht mehr erinnern und lässt deine Verletzungen bei Gelegenheit von einem richtigen Arzt (Spieler), untersuchen und behandeln.<br/><br/>Diese Regel zählt, nur wenn du vom System zum Krankenhaus teleportiert wirst und nicht wenn du von einem Spieler vor Ort reanimiert wirst!"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewusstlosigkeit (Wiederbeleben)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solltest du bewusstlos werden, darfst du dich ausschließlich wiederbeleben, wenn kein Spieler in der Nähe ist. Diese Regel entfällt, wenn andere Spieler deutlich zu erkennen geben, dass kein Sanitäter erscheinen wird (z.B. kein Sanitäter Online) oder wenn dich die umgebenden Spieler ignorieren. <br/>Beispiel: Die Schießerei ist noch aktiv und keiner kümmert sich um dich oder macht anzeichen einen Mediziner zu kontaktieren."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewusstlosigkeit ausspielen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solltest du bewusstlos werden und vom System im Krankenhaus aufwachen, dann hast du die Pflicht dein RolePlay weiter fortzuführen, auch wenn du durch z.B. einen Bug gestorben bist. Komme einfach nach vollenden deines RolePlays in den Support, damit die Sache geklärt werden kann."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitszonen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Folgenden siehst du eine Liste von Sicherheitszonen, an denen keine Leute ausgeraubt oder als Geisel genommen werden dürfen.<br/>Sicherheitszonen:<br/>➥ Geldautomaten<br/>➥ Bank<br/>➥ Flughafen"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flüchten in Sicherheitszonen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Flüchten aus einer RolePlay-Situation in eine Sicherheitszone ist strengstens verboten und führt zur kurzzeitigen Aufhebung der Regel 'Zonen' für diese Situation.<br/>Beispiel: Du flüchtest vor Verfolger in eine Sicherheitszone, die dich ausrauben wollen."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Versenden von Reallife Bildern über SMS ist verboten. Es dürfen nur Minecraft Screenshots welche keine Teile des Huds oder GUIS enthalten versendet werden, so wie Screenshots von Textdokumenten. Das zuspamen von Leuten im Roleplay ist streng verboten!"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extremsituationen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jegliche Art von Rassismus, Vergewaltigung oder übermäßiger Folter im Rahmen des Rollenspiels ist verboten. Hierzu zählt das Ausspielen von Extremsituationen, wie zum Beispiel „Verbrechen aus Rassenhass“, „Vergewaltigung“, „Terrorismus” oder „Zerstückelung, bzw. Abschneiden von Körperteilen“."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestalter"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir erwarten von jedem Roleplay-Spieler ein Mindestalter von 14 Jahren so wie angemessenes Verhalten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covid-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ausspielen von Corona ist auf unserem Projekt nicht erwünscht."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itemorientiertes Ausrauben"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ausrauben von Spielern ist verboten, wenn die Handlung ein reinen itemorientieren Hintergrund hat. Dieser Fall tritt ein wenn es keinen RolePlay-Hintergrund gibt oder zu viele Items von diesem Spieler entwendet wurden."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffenregel"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Verwenden einer Waffe als Druck-/Machtmittel ist eine legitime Verwendung, dies sollte aber erst nach vorherigen RP-Zusammenhängen geschehen und nicht als erste Tat in Betracht gezogen werden. Vorherige Zusammenhänge sind z.B. Versuchen die Situation mit Worten zu klären. Dies gilt für jeden Spieler, unabhängig von seiner Fraktion."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFK-Farming"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist verboten AFK auf dem Server zu sein mit Gewinnabsichten. Zum Beispiel das Abwarten von Paydays oder das Verarbeiten von Rohstoffen. Man hat immer ansprechbar zu sein, während diesen Tätigkeiten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOC-Talk"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeglicher OOC Talk ist strengstens untersagt. Dazu zählt auch das einfache Ersetzen von OOC Begriffen, wie zum Beispiel \"Taste\" durch \"Muskel\". Stattdessen sollten kreative Umschreibungen verwendet werden. Äußerungen wie \"Ich bin kurz im Kopf/Gedanken\" sind ebenfalls unerwünscht. Stattdessen sollten alternative Formulierungen, wie beispielsweise \"Ich schaue schnell auf mein Handy, um meine Nachrichten zu checken\" verwendet werden."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspeak und Discord"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftreten"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jegliche beleidigende, rassistische, sexistische und extremistische Aussage (Namen, Channelnamen, Avatare, Nachrichten, ...) ist verboten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umgangston"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Beleidigen von anderen Personen ist verboten. Das Drohen anderer Personen mit jeglichen Aktivitäten ist nicht erlaubt. Es sollte darauf geachtet werden, dass in keinem frei zugänglichem Gesprächsraum geschrien wird. Es sollte mit keiner anderen Person respektlos geredet werden, aufgrund seiner/ihrer Meinungsäußerung."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräuschkulisse"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Verzerren der eigenen Stimme ist verboten. Das erzeugen oder nachmachen von unangemessenen Geräuschen sowie das Singen ist zu unterlassen. Das Abspielen jeglicher Töne oder Musik ist nicht erlaubt. Es sollte darauf geachtet werden, keine zu lauten oder rauschenden Geräusche zu verursachen."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprächsaufzeichnung und Streaming"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Aufnehmen von Gesprächen so wie das Streamen ohne Erlaubnis aller Anwesenden ist verboten. (Ausnahme ist der '» Ingame' Channel auf Teamspeak)"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsdiebstahl"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Fälschen anderer Personen ist in jeglicher Art und Weise verboten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportausnutzung"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ausnutzen unseres Supportes (z.B. die Nutzung ohne Benötigung oder das Stellen unnötiger Fragen) ist verboten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scripts"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Verwenden von Scripts zum Erstellen von Channeln, Bots und Sonstiges ist verboten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugusing"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ausnutzen oder Verbreiten eines Bugs ist nicht erlaubt."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Veröffentlichung oder Weitergebung von Person bezogenen Daten anderer Leute ohne deren Erlaubnis, ist strengstens untersagt. Ausnahmen gibt es hierbei bei polizeilichen Angelegenheiten, jedoch müssen diese dann bei den Eigentümern dieses TeamSpeak Servers angefragt werden."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang ausnutzen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte man eine Server Gruppe haben, welche höher liegt als die Standard Server Gruppe, so ist es ihm/ihr strengstens untersagt seine/ihre Rechte für nicht vorgegebene Benutzung zu verwenden (z.B. das grundlose Kicken oder Bannen anderer Leute)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angriffe"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jegliche Angriffe auf andere Personen und unsere Server (in Form von DDOS, PokeBot, Hacking, ...) ist untersagt."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banumgehung"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Umgehung eines Bannes von unseren Servern mit beispielsweiße VPN ist verboten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TS³ Name"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist verboten einen TS³ Namen zu wählen, der OOC/IC Vermischung enthält (z.B. [Greens OG], [Polizeihauptmeister], Max Mustermann, etc.). Ebenfalls sind unklare Namen wie ???? und XXXX untersagt."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Modifikationen (Mods)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jegliche Modifikationen des Clients, welche dem Spieler einen Vorteil gegenüber anderen Mitspielern verschaffen, sind grundsätzlich untersagt. Unerlaubte Modifikationen sind zum Beispiel:<br/>➥ Xray<br/>➥ KillAura<br/>➥ Minimap"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugs"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jegliche Form von Bugs und deren Ausnutzung sind streng untersagt. Wenn ihr ein Bug entdeckt, solltet ihr es beim Team melden."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umgehung von Strafen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Umgehen von Strafen durch beispielsweise einem 2. Account ist verboten."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountverantwortlichkeit"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder ist für seinen eigenen Account verantwortlich. Daher wird eine Strafe auch dann ausgesprochen, wenn eine zweite Person außer dem Eigentümer einen Regelverstoß begeht."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatverhalten"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Chat sollte auf einen respektvollen Umgang geachtet werden. Das Spamen von Nachrichten ist zu unterlassen."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDM"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist verboten, andere Leute zu töten, ohne sich sicher zu sein, dass es sich um einen Spieler aus dem Gegnerteam handelt."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghosting"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es dürfen keine Infos über das laufende Spiel über Kommunikationsmöglichkeiten ausserhalb geteilt werden."])}
        }
      ]
    }
  ]
}